<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.venue_reservation_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                        <ValidationProvider name="Organization Name"  vid="org_id">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.organization')}}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.org_id"
                              :options="orgList"
                              :disabled="!$store.state.Auth.activeRoleId || $store.state.Auth.activeRoleId !== 1"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Vanue Name" vid="venue_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="venue_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_tpm.vanue')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="search.venue_id"
                                :options="venueList"
                                id="venue_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                            <ValidationProvider name="Start Date" vid="start_date">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="start_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.start_date') }}
                                </template>
                                <b-form-input
                                    class="fromDate"
                                    v-model="search.start_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="End Date" vid="end_date">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="end_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.end_date') }}
                                </template>
                                <b-form-input
                                    class="fromDate"
                                    v-model="search.end_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                          &nbsp;
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('tpm_report.venue_reservation_report') }}</h4>
              </template>
              <template v-slot:headerAction>
                <b-button class="btn_add_new" @click="pdfExport">
                  <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                </b-button>
              </template>
              <template v-slot:body>
                <b-overlay :show="loading">
                  <b-row mt-5  v-if='datas.length > 0'>
                    <b-col md="12" class="table-responsive">
                      <div style="border: 2px solid;margin:10px;padding:10px">
                        <b-row>
                          <b-col>
                            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                              <template v-slot:projectNameSlot>
                                {{ }}
                              </template>
                              {{ $t('tpm_report.venue_reservation_report') }}
                            </list-report-head>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <div class="text-center">
                              <table style="width:100%;color:black;">
                                <tr v-if="search.fiscal_year">
                                  <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                </tr>
                                <tr v-if="search.org_id">
                                  <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_name_bn : search.org_name }}</td>
                                </tr>
                                <tr v-if="search.venue_id">
                                  <td align="right" style="width:45%">{{ $t('elearning_tpm.vanue') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.vanue_name_bn : search.vanue_name }}</td>
                                </tr>
                                <tr v-if="search.start_date">
                                  <td align="right" style="width:45%">{{ $t('globalTrans.start_date') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ search.start_date | dateFormat }}</td>
                                </tr>
                                <tr v-if="search.end_date">
                                  <td align="right" style="width:45%">{{ $t('globalTrans.end_date') }}</td>
                                  <td align="center" style="width:5%">:</td>
                                  <td align="left" style="width:50%">{{ search.end_date | dateFormat }}</td>
                                </tr>
                              </table>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                              <b-thead>
                                <b-tr>
                                  <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                  <b-th style="width:10%" class="text-center">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                  <b-th class="text-center">{{ $t('elearning_config.organization') }}</b-th>
                                  <b-th class="text-center">{{ $t('elearning_tpm.vanue') }}</b-th>
                                  <b-th class="text-left">{{ $t('elearning_venue.reservation_info') }}</b-th>
                                  <b-th class="text-center">{{ $t('globalTrans.start_date') }}</b-th>
                                  <b-th class="text-center">{{ $t('globalTrans.end_date') }}</b-th>
                                  <b-th class="text-center">{{ $t('globalTrans.day') }}</b-th>
                                  <b-th class="text-center">{{ $t('tpm_report.total_amount') }}</b-th>
                                </b-tr>
                              </b-thead>
                              <b-tbody v-for="(info, index) in datas" :key="index">
                                <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.fiscal_year_bn : info.fiscal_year }}</b-td>
                                <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.org_bn : info.org }}</b-td>
                                <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.venue_bn : info.venue }}</b-td>
                                <b-td class="text-left">
                                    {{$t('globalTrans.name')}} : {{ $i18n.locale === 'bn' ? info.name_bn : info.name }}
                                    <br/>
                                    {{$t('globalTrans.email')}} : {{ info.email }}
                                    <br/>
                                    {{$t('globalTrans.mobile')}} : {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(info.mobile, { useGrouping: false }) }}
                                    <br/>
                                    {{$t('globalTrans.address')}} : {{ $i18n.locale === 'bn' ? info.reservation_address_bn : info.reservation_address }}
                                    <br/>
                                    {{$t('elearning_config.organization')}} : {{ $i18n.locale === 'bn' ? info.reservation_org_bn : info.reservation_org }}
                                </b-td>
                                <b-td class="text-center">
                                  {{ info.start_date | dateFormat }} <br/>
                                  {{ info.start_time | time12Formate  }}
                                </b-td>
                                <b-td class="text-center">
                                  {{ info.end_date | dateFormat }} <br/>
                                  {{ info.end_time | time12Formate  }}
                                </b-td>
                                <b-td class="text-center">{{ $n(info.days) }}</b-td>
                                <b-td class="text-center">{{ $n(info.payment_amount) }}</b-td>
                              </b-tbody>
                            </b-table-simple>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="panel-body text-center mt-3" v-else>
                    <h6 class="text-danger" v-if="isLoadThis">{{ $t('globalTrans.noDataFound') }}</h6>
                  </div>
                </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <!-- <pre>{{datas}}</pre> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { venueReservationReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import moment from 'moment'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
import Store from '@/store'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead
    },
    created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
    },
    data () {
        return {
          search: {
            org_id: 0,
            venue_id: 0,
            start_date: '',
            end_date: ''
          },
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          venueList: [],
          showData: false,
          isLoadThis: false,
          isLoading: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    watch: {
      'search.org_id': function (newValue) {
        this.venueList = this.geVenueList(newValue)
      }
    },
    methods: {
      geVenueList (orgId) {
        const venueList = this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
        if (orgId) {
          return venueList.filter(item => item.org_id === orgId)
        }
        return venueList
      },
      pdfExport () {
          const reportTitle = this.$t('tpm_report.venue_reservation_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
      },
      async searchData () {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        this.showData = true
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
        this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
        this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id)
        this.search.fiscal_year = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_en : ''
        this.search.fiscal_year_bn = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_bn : ''

        const vanueObj = this.$store.state.TrainingElearning.commonObj.venus.find(item => item.value === this.search.venue_id)
        this.search.vanue_name = typeof vanueObj !== 'undefined' ? vanueObj.text_en : ''
        this.search.vanue_name_bn = typeof vanueObj !== 'undefined' ? vanueObj.text_bn : ''

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, venueReservationReport, this.search)
        if (result.success) {
          Store.commit('mutateCommonProperties', {
            loading: false
          })
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
          }
        } else {
          this.datas = []
          Store.commit('mutateCommonProperties', {
            loading: false
          })
        }
        this.isLoadThis = true
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const start = moment(item.start_date, 'YYYY-MM-DD')
          const end = moment(item.end_date, 'YYYY-MM-DD')
          const days = end.diff(start, 'days') + 1
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
            orgData.days = days
          } else {
            orgData.org = ''
            orgData.org_bn = ''
            orgData.days = ''
          }
          // const countDay = {}
          // if (typeof days !== 'undefined') {
          //   countDay.day = days
          // } else {
          //   countDay.day = ''
          // }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const venueObj = this.$store.state.TrainingElearning.commonObj.venus.find(doc => doc.value === parseInt(item.venue_id))
          const venueData = {}
          if (typeof venueObj !== 'undefined') {
            venueData.venue = venueObj.text_en
            venueData.venue_bn = venueObj.text_bn
          } else {
            venueData.venue = ''
            venueData.venue_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const reservationOrgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.reservation_org_id))
          const reservationOrgData = {}
          if (typeof reservationOrgObj !== 'undefined') {
            reservationOrgData.reservation_org = reservationOrgObj.text_en
            reservationOrgData.reservation_org_bn = reservationOrgObj.text_bn
          } else {
            reservationOrgData.reservation_org = ''
            reservationOrgData.reservation_org_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, venueData, OfficeTypeData, reservationOrgData)
        })
        return listData
      }
    }
}
</script>
