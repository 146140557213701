import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import { dateFormat, time12Formate } from '@/Utils/fliter'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRowsHead = []
        if (search.fiscal_year) {
          const rowItem = [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fiscal_year_bn : search.fiscal_year, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.org_name) {
          const rowItem = [
            { text: vm.$t('elearning_config.organization'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.org_name_bn : search.org_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.venue_id) {
          const rowItem = [
            { text: vm.$t('elearning_tpm.vanue'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.vanue_name_bn : search.vanue_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.start_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.start_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.start_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.end_date) {
          const rowItem = [
            { text: vm.$t('globalTrans.end_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.end_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['45%', '5%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tpm.vanue'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_venue.reservation_info'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.start_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.end_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.day'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('tpm_report.total_amount'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        data.forEach((info, index) => {
            // let isPresent
            // if (info.mobile) {
            //     isPresent = vm.$t('elearning_tim.absent')
            // }
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.fiscal_year_bn : info.fiscal_year, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.org_bn : info.org, alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.venue_bn : info.venue, alignment: 'center', style: 'td' },
            { text: (vm.$t('globalTrans.name') + ' : ' + (i18n.locale === 'bn' ? info.name_bn : info.name)) + '\n' + vm.$t('globalTrans.email') + ' : ' + (info.email) + '\n' + vm.$t('globalTrans.mobile') + ' : ' + ((vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(info.mobile, { useGrouping: false })) + '\n' + (vm.$t('globalTrans.address') + ' : ' + (i18n.locale === 'bn' ? info.reservation_address_bn : info.reservation_address)) + '\n' + (vm.$t('globalTrans.organization') + ' : ' + (i18n.locale === 'bn' ? info.reservation_org_bn : info.reservation_org)), alignment: 'left', style: 'td' },
            { text: dateFormat(info.start_date) + '\n' + time12Formate(info.start_time), alignment: 'center', style: 'td' },
            { text: dateFormat(info.end_date) + '\n' + time12Formate(info.end_time), alignment: 'center', style: 'td' },
            { text: vm.$n(info.days), alignment: 'center', style: 'td' },
            { text: vm.$n(info.payment_amount), alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['5%', '10%', '14%', '12%', '25%', '10%', '10%', '5%', '9%'],
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 9 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
        //   address: {
        //     fontSize: 9,
        //     margin: [0, -10, 0, 0]
        //   },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
